import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RootComponent from './Components/root/RootComponent';
import { getCurrentTime, checkImExpiry, callReauth, newAppendData, getGlid, gatrack, writeLogs, getHtUrl, redirectToHt, getFinaggUrl, redirectToFinagg, isFinaggLive } from './utils';
import { format } from 'date-fns';

const startTime = getCurrentTime();

async function pageLoadFunc() {
  let glid = getGlid();

  if (glid != "" ) {
    const queryParamString = window.location.search.substring(1);
    writeLogs(glid, "Desktop", queryParamString, "SUCCESS", "LOANS_LANDING", 200, "NA", format(startTime, 'dd-MM-yyyy HH:mm:ss'), "NA", 0);
    gatrack("Intent Capture", "Landing", "Landed");

    const htUrlPromise = isFinaggLive(glid) ? Promise.resolve("") : getHtUrl(glid);
    const finaggUrlPromise = isFinaggLive(glid) ? getFinaggUrl(glid) : Promise.resolve("");
    const imExpiryPromise = checkImExpiry();

    let imStatus = await imExpiryPromise;
    
    if (imStatus === "expired") {
      gatrack("Login Status", "Reauth Call", "AK Expired");
      callReauth();
      //return; // Stop execution to prevent rendering RootComponent
    } else if (imStatus == "notExpired") {
      gatrack("Login Status", "Already Login", "Full Login");
      const redUrl = await htUrlPromise;
      const finUrl = await finaggUrlPromise;

      if(isFinaggLive(glid)){
        redirectToFinagg(glid, finUrl, startTime);
      } else{
        redirectToHt(glid, redUrl, startTime);
      }

      //newAppendData(glid, startTime);
      //return; // Stop execution to prevent rendering RootComponent
    } else {
      console.log("partial logged in user.")
      gatrack("Login Status", "Full Login Absent", "AK or GLID missing");
    }
  }

  // Only render RootComponent if no redirection happened
  renderApp();
}

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<RootComponent startTime={startTime} />);
}

// Call pageLoadFunc before rendering
pageLoadFunc();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
