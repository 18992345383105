import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../CssFiles/newHomePage.css";

// const iciciLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/ICICI_Bank_Logo-01.jpg";
const lendingkartLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/lendingkartlogo-.svg";
const creditenableLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/CreditEnable_Logo_Dark_Blue.png";
const andromedaLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/Andromeda.jpeg(2)(1).jpg";
const psbLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/PSB59-Logo-for-IndiaMart.png";
const pirimid = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/Ignosis_Logo_Horizontal.svg";
const creditmantriLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/CM-Logo-1920.png";
const oneinfinityLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/OI_logo.jpg";
const loantapLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/HQ_LoanTap_logo_.png";
const poonawallaLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/PFL-White-Logo.jpg";
const TataCapitalLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/tataCapital.png";
const indifiLogo = "https://loans.imimg.com/desktop_react/src/Images/lenderimages/Indifi_logo-01.png";

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
//   arrows: true,
  centerMode: false,
  prevArrow: (
    <button className="slick-prev">
      <i className="bi bi-chevron-left"></i> {/* Bootstrap left arrow */}
    </button>
  ),
  nextArrow: (
    <button className="slick-next">
      <i className="bi bi-chevron-right"></i> {/* Bootstrap right arrow */}
    </button>
  ),
};

class PartnerCarouselComponent extends Component {
  render() {
    return (
      <>
        <section
          className="footer"
          style={{ minWidth: "300px", marginTop: "70px", marginBottom: "40px" }}
        >
          <div className="footerBoxStyle" id="partner-carousel">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginLeft: "20px",
              }}
            >
              <div className="headingStyle">Our Partners</div>
              <div style={{ marginTop: "8px", width: "100%" }}>
                <div className="container" style={{ padding: "0px" }}>
                  <Slider {...settings} className="custom-slider">
                    {/* <div >
                            <div className = 'lendersBoxStyle'>
                                <img src={iiflLogo} style={{width:'100px', height:'40px'}}/>
                            </div>
                            </div> */}
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src={creditenableLogo}
                          style={{ width: "120px", height: "40px" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src={oneinfinityLogo}
                          style={{ width: "100px", height: "45px" }}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="lendersBoxStyle">
                        <img src={creditmantriLogo} style={{ width: "60%" }} />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img src={lendingkartLogo} style={{ width: "50%" }} />
                      </div>
                    </div>
                    {/* <div>
                      <div className="lendersBoxStyle">
                        <img src={iciciLogo} style={{ width: "50%" }} />
                      </div>
                    </div> */}
                    <div>
                      <div className="lendersBoxStyle">
                        <img src={psbLogo} style={{ width: "70%" }} />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img src={andromedaLogo} style={{ width: "30%" }} />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img src={TataCapitalLogo} style={{ width: "64%" }} />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src="https://loans.imimg.com/images/loans/lenderimages/finagg_logo_new.png"
                          style={{ width: "64%" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src="https://loans.imimg.com/images/loans/lenderimages/velocity_logo.png"
                          style={{ width: "64%" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img src={indifiLogo} style={{ width: "45%" }} />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src={loantapLogo}
                          style={{ width: "120px", height: "40px" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="lendersBoxStyle">
                        <img
                          src={poonawallaLogo}
                          style={{ width: "120px", height: "40px" }}
                        />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PartnerCarouselComponent;
