import axios from "axios";
import Cookies from 'universal-cookie';

export async function getConsolidatedData(days, service) {
    const host = window.location.hostname;
    const empId = getEmpId();
    const empAk = getEmpAk();

    const getConsDataURL = host === 'loans.indiamart.com'
        ? "https://loans.indiamart.com/api/v1/getConsData"
        : "https://dev-loans.indiamart.com/api/v1/getConsData";

    const formData = new URLSearchParams({
        days: days,
        service: service,
        encEmpId: empId,
        encToken: empAk
    });


    try {
        const { data } = await axios.post(getConsDataURL, formData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            timeout: 10000
        });

        //console.log("Bureau data_obj =>", data);
        return data?.data;
    } catch (error) {
        console.error("An error occurred in utils gstapi call:", error);
        return "";
    }
}

export async function getIndBureau(glid) {
    const host = window.location.hostname;
    const empId = getEmpId();
    const empAk = getEmpAk();

    const getConsDataURL = host === 'loans.indiamart.com' 
        ? "https://loans.indiamart.com/api/v1/getBureauData"
        : "https://dev-loans.indiamart.com/api/v1/getBureauData";

    const formData = new URLSearchParams({
        glid:glid,
        encEmpId: empId,
        encToken: empAk
    });

    try {
        const { data } = await axios.post(getConsDataURL, formData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            timeout: 10000
        });

        console.log("Bureau data_obj =>", data);
        return data?.data;
    } catch (error) {
        console.error("An error occurred in utils gstapi call:", error);
        return "";
    }
}

export function handleDownloadClick(url){
    if(url !== ""){
        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/viewDoc";
        }
        else {
            var apiURL = "https://dev-loans.indiamart.com/api/v1/viewDoc";
        }

        var inData = {
            "url":url
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: apiURL,
            data: inData,
            timeout:2000
        }).then(function (response) {
            if(response.data.CODE === "200"){
                window.open(response.data.PDF_URL,'_blank');
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
};

export function getEmpId() {
    const cookie = new Cookies();
    let encrEmpId = cookie.get('adminiil');
    return encrEmpId ? encrEmpId : "";
}

export function getEmpAk() {
    const cookie = new Cookies();
    let encrToken = cookie.get('im_iss');
    return encrToken ? encrToken : "";
}

