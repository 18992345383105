import React from "react";
import { useState ,useRef } from "react";
import axios from 'axios';
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

function CompanyComponent(props){
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSubOption, setSelectedSubOption] = useState('');
    const [propFile,setPropFile] = useState(null);
    const [isInvalidClick,setIsInvalidClick] = useState('');
    const [show,setShow] = useState(false);
    const [showBox,setShowBox] = useState(false);
    const [uploadStatus,setUploadStatus] = useState('');
    const [partners, setPartners] = useState('');
    const [dbColumnType,setDbColumnType] = useState('');
    const [personType,setPersonType] = useState('');
    const [docPwd,setDocPwd] = useState('');
    const [invalidPartner,setInvalidPartner] = useState('');

    const stateVals = props.states;
    const styleClass = props.states.styles;
    const propRef = useRef(null); 

    const mapArr = {
        "director_pan": "PAN",
        "director_aadhar": "Aadhar",
        "director_photo": "Photo",
        "director_electricity_bill": "Electricity Bill",
        "director_property_bill": "Property Bill",
        "company_pan": "Company PAN",
        "company_electricity_bill": "Company Electricity Bill",
        "company_property_bill": "Company Property Bill",
        "company_rent_agreement": "Company Rent Agreement",
        "company_gst_certificate": "Company GST Certificate",
        "company_msme_certificate": "Company MSME Certificate",
        "company_udhyam_certificate": "Company Udhyam Certificate",
        'company_aoa': 'Company AOA',
        'company_moa': 'Company MOA',
        'company_coi': 'Company COI',
        'company_directors_list': 'List of Directors',
        'company_shareholders_list': 'List of Shareholders'
    };    

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectedSubOption('')
        setShowBox(false);
    };

    const handlePropDocChange = (event) => {
        setPropFile(event.target.files[0]);
    };

    const handlePartnerUploadClick = (upload_flag = '') => {
        console.log(selectedOption)
        console.log(selectedSubOption)

        if(selectedOption === ""){
            setIsInvalidClick('Please select the option.');
            return;
        }
        
        if(propFile === null){
            setIsInvalidClick('Please upload the file.');
            return;
        }

        setIsInvalidClick('');

        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/uploadKYC";
        }
        else{
            var apiURL = "https://dev-loans.indiamart.com/api/v1/uploadKYC";
        }

        var fileType = propFile.type;

        if(fileType === 'application/pdf'){
            fileType = 'pdf';
        }
        else if(fileType === 'image/png'){
            fileType = 'png';
        }
        else if(fileType === 'image/jpeg'){
            fileType = 'jpeg';
        }
        else if(fileType === 'image/jpg'){
            fileType = 'jpg';
        }
        else{
            fileType = 'Unknown';
        }

        var postData = {
            "glid":stateVals.respGlid,
            "docType":fileType,
            "uploaderId":stateVals.user,
            "file": propFile,
            "docDesc":dbColumnType,
            "const_type":upload_flag,
            "person_type":personType,
            "docCredentials":docPwd
        };

        // console.log(postData);
        // return;

        const formData = new FormData();
        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                formData.append(key, postData[key]);
            }
        }
        
        axios({
            method: 'post',
            headers: {  'Content-Type': 'multipart/form-data', },
            url: apiURL,
            data: formData,
            timeout:2000
            }).then(function (response) {
                var resp = response.data;
                if(resp.CODE == "200"){
                    setUploadStatus("Doc uploaded successfully.")
                }
                else{
                    setUploadStatus("Something went wrong. Please try again.")
                }
                stateVals.getKYCInfo("company")
                propRef.current.value = null;
                setPropFile(null);
                setSelectedOption('');
                setSelectedSubOption('');
                setDocPwd('');
            }).catch(function (error) {
                console.log(error);
            });
    };

    const handleViewClick = () => {
        setShow(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getUTCFullYear();
        return `${day}-${month}-${year}`;
    };

    const handlePartnersChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Restrict to numeric input
        if(value <= 10){
            setPartners(value);
        }
    };

    const setParams = (docDesc, dbName, person = '') => {
        handleOptionClick(docDesc);
        setDbColumnType(dbName);
        setPersonType(person);
    };

    const handleDocPwdChange = (event) => {
        setDocPwd(event.target.value)
    };

    const handleDropdownClick = () => {
        if(partners < 1 || partners > 10){
            setInvalidPartner("Please enter a valid value.")
            return;
        }
        setInvalidPartner('');
        setShowBox(!showBox)
    };

    return (
        <div>
            <div id="kycPartner" style={{...styleClass.midSection,textAlign:'center',width:'32%',margin:'0 auto',height:'330px',marginBottom:'150px'}}>
                    <div style={{fontSize:'18px',fontWeight:'bold'}}>KYC Documents - Company</div>

                    <div>
                <label htmlFor="partnersInput" style={{ margin: '8px 8px 0px' }}>
                    How many Directors?
                </label>
                <input
                    type="text"
                    id="partnersInput"
                    name="partnersInput"
                    pattern="[0-9]*"
                    value={partners}
                    inputMode="numeric"
                    style={{ padding: '4px', borderRadius: '4px', border: '1px solid #ccc',width:'60px' }}
                    min="1"
                    max="10"
                    onChange={handlePartnersChange}
                />
                </div>

                {
                    invalidPartner != '' &&
                    <div style={{color:'red',fontSize:'12px',margin:'5px 0px'}}>{invalidPartner}</div>
                }

                <div>
                <label>Choose an option:</label>
                <div
                style={{
                    padding: '4px',
                    borderRadius: '4px',
                    marginLeft: '6px',
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'inline-block',
                }}
                >
                <div
                    style={{
                        backgroundColor: '#FFF',
                        border: '1px solid #ccc',
                        minWidth: '163px',
                        width:'163px',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        textAlign:'left',
                        paddingLeft:'8px'
                    }}
                    onClick={handleDropdownClick}
                >
                    {selectedSubOption != '' ? selectedSubOption :  (selectedOption != '' ? selectedOption : 'Select an option')}
                </div>

                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: '#FFF',
                        border: '1px solid #ccc',
                        zIndex: 1,
                        minWidth: '10px',
                        borderRadius: '4px',
                        display: showBox ? 'block' : 'none',
                        textAlign:'left'
                    }}
                >

                    <div id="panPartner" className="kycDocsPartners" style={{paddingTop:'6px'}}>
                        Directors PAN
                        <div id="commonPartner" className="commonPartner">
                        {Array.from({ length: partners }).map((_, i) => (
                            <div key={i} id={`panDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }} 
                            onClick={() => setParams(`Director ${i + 1} PAN`, "director_pan", i + 1)}>
                            Director {i + 1} PAN
                            </div>
                        ))}
                        </div>

                        {/* {
                            partners == '' &&
                            <div id="commonPartner" className="commonPartner" style={{display : partners == '' ? 'block' : 'none'}}>Please select number of partners.</div>
                        } */}

                        <i className="bi bi-caret-down commonArrow" style={{ marginLeft: '8px', transition: 'transform 0.3s ease' }}></i>
                    </div>


                    <div id="aadharPartner" className="kycDocsPartners">
                        Directors Aadhar
                        <div id="commonPartner" className="commonPartner">
                        {Array.from({ length: partners }).map((_, i) => (
                            <div key={i} id={`aadharDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }}
                            onClick={() => setParams(`Director ${i + 1} Aadhar`, "director_aadhar", i + 1)}
                            >
                            Director {i + 1} Aadhar
                            </div>
                        ))}
                        </div>
                        <i className="bi bi-caret-down commonArrow" style={{ marginLeft: '8px', transition: 'transform 0.3s ease' }}></i>
                    </div>


                    <div id="photoPartner" className="kycDocsPartners">
                        Directors Photo
                        <div id="commonPartner" className="commonPartner">
                        {Array.from({ length: partners }).map((_, i) => (
                            <div key={i} id={`photoDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }}
                            onClick={() => setParams(`Director ${i + 1} Photo`, "director_photo", i + 1)}>
                            Director {i + 1} Photo
                            </div>
                        ))}
                        </div>
                        <i className="bi bi-caret-down commonArrow" style={{ marginLeft: '8px', transition: 'transform 0.3s ease' }}></i>
                    </div>

                    <div id="resiProofPartner" className="kycDocsPartners"> 
                        Residence Proof
                        <div id="resiDrpdownPartner" className="resiDrpdownPartner">

                            {/* Electricity Bill */}
                            <div id="elecbill" className="hoverable-parent" style={{ paddingLeft: '8px', paddingTop: '4px', position: 'relative',display:'flex',justifyContent:'space-between' }} >
                                Electricity Bill
                                <div id="subCommonPartner" className="subCommonPartner">
                                    {Array.from({ length: partners }).map((_, i) => (
                                        <div key={i} id={`elecbillDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }} 
                                        onClick={() => setParams(`Director ${i + 1} Electricity Bill`, "director_electricity_bill", i + 1)}>
                                            Director {i + 1} Electricity Bill
                                        </div>
                                    ))}
                                </div>
                                <i className="bi bi-caret-down subCommonArrow" style={{ marginRight:'6px', transition: 'transform 0.3s ease' }}></i>
                            </div>

                            {/* Property Bill */}
                            <div id="propertyBill" className="hoverable-parent" style={{ paddingLeft: '8px', paddingTop: '4px', position: 'relative',display:'flex',justifyContent:'space-between' }} >
                                Property Bill
                                <div id="subCommonPartner" className="subCommonPartner">
                                    {Array.from({ length: partners }).map((_, i) => (
                                        <div key={i} id={`propertyBillDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }} 
                                        onClick={() => setParams(`Director ${i + 1} Property Bill`, "director_property_bill", i + 1)}
                                        >
                                            Director {i + 1} Property Bill
                                        </div>
                                    ))}
                                </div>
                                <i className="bi bi-caret-down subCommonArrow" style={{ marginRight:'6px', transition: 'transform 0.3s ease' }}></i>
                            </div>

                            {/* Rent Agreement */}
                            {/* <div id="rentAgreement" className="hoverable-parent" style={{ paddingLeft: '8px', paddingTop: '4px', position: 'relative',display:'flex',justifyContent:'space-between' }} >
                                Rent Agreement
                                <div id="subCommonPartner" className="subCommonPartner">
                                    {Array.from({ length: partners }).map((_, i) => (
                                        <div key={i} id={`rentAgreementDrpdownPartner_${i}`} className="hoverable" style={{ padding: '4px 8px' }} onClick={() => setParams(`Partner ${i + 1} Rent Agreement`, "partner_rent_agreement", i + 1)}>
                                            Director {i + 1} Rent Agreement
                                        </div>
                                    ))}
                                </div>
                                <i className="bi bi-caret-down subCommonArrow" style={{ marginRight:'6px', transition: 'transform 0.3s ease' }}></i>
                            </div> */}

                        </div>
                        <i className="bi bi-caret-down resiDownArrPartner" style={{ marginLeft: '8px' }}></i>
                    </div>

                    <div id="bkycPartner" className="kycDocsPartners">
                    Business KYC
                    <div id="bkycDrpdownPartner" className="bkycDrpdownPartner">
                        <div className="hoverable subDropdown" 
                        onClick={() => setParams('Company MSME Certificate','company_msme_certificate')}>MSME Certificate</div>

                        <div className="hoverable subDropdown"  
                        onClick={() => setParams('Company Udhyam Certificate','company_udhyam_certificate')}>Udhyam Certificate</div>

                        <div className="hoverable subDropdown" 
                         onClick={() => setParams('Company AOA','company_aoa')}>Company AOA</div>

                        <div className="hoverable subDropdown"
                        onClick={() => setParams('Company MOA','company_moa')}>Company MOA</div>

                        <div className="hoverable subDropdown"
                        onClick={() => setParams('Company COI','company_coi')}>Company COI</div>

                        <div className="hoverable subDropdown"
                        onClick={() => setParams('List of Directors', 'company_directors_list')}>List of Directors</div>

                        <div className="hoverable subDropdown"
                        onClick={() => setParams('List of Shareholders', 'company_shareholders_list')}>List of Shareholders</div>
                    </div>
                    <i className="bi bi-caret-down bkycDownArrPartner" style={{ marginLeft: '8px' }}></i>
                    </div>

                    <div className="hoverable kycDocsPartners" 
                    onClick={() => setParams('Company PAN','company_pan')}>Company PAN</div>

                    <div id="officeProof" className="kycDocsPartners"> 
                    Company Address Proof
                    <div id="officeDrpdown" className="officeDrpdown">
                        <div className="hoverable subDropdown"  
                        onClick={() => setParams('Company Electricity Bill','company_electricity_bill')}>Electricity Bill</div>

                        <div className="hoverable subDropdown" 
                        onClick={() => setParams('Company Property Bill','company_property_bill')}
                        >Property Bill</div>

                        <div className="hoverable subDropdown" 
                        onClick={() => setParams('Company Rent Agreement','company_rent_agreement')}
                        >Rent Agreement</div>
                    </div>
                    <i className="bi bi-caret-down officeDownArr" style={{ marginLeft: '8px' }}></i>
                    </div>

                    <div className="hoverable kycDocsPartners" 
                    onClick={() => setParams('GST Certificate','company_gst_certificate')}>GST Certificate</div>

                    {/* <div className="hoverable kycDocsPartners" onClick={() => setParams('Partnership Agreement','company_partnership_agreement')} style={{paddingBottom:'6px'}}>Partnership Agreement</div> */}
                </div>
                </div>

                </div>

                <div style={{marginTop:'10px'}}>
                <input
                            type="file"
                            id="propUpload"
                            name="propUpload"
                            accept="application/pdf, image/png, image/jpeg, image/jpg"
                            style={{...styleClass.fileInput}}
                            onChange={handlePropDocChange}
                            ref={propRef}
                        />

                        {
                            isInvalidClick !== "" &&
                            <div style={{color:'red',fontSize:'12px',paddingTop:'10px',paddingLeft:'15px'}}>{isInvalidClick}</div>
                        }

                        <div style={{margin:'10px 0px'}}>
                            <label>Doc Pwd:</label>
                            <input type="text" style={{marginLeft:'5px',borderRadius:'4px',border:'none'}} value={docPwd} onChange={handleDocPwdChange}/>
                        </div>
                        
                        {/* <div style={{...styleClass.customButton,margin:'30px 10px'}} onClick={() => handlePartnerUploadClick('company')}>
                            Upload Document
                        </div> */}

                        {
                        stateVals.isViewVisible && 
                        <button
                        style={styleClass.bankingCTA}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007BFF'}    
                        onClick={handleViewClick}
                        >
                            View
                        </button>
                    }
                    
                    {
                        uploadStatus != '' &&
                        <div style={{color:'red',fontSize:'12px'}}>{uploadStatus}</div>
                    }

                <Modal 
                    show={show}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShow(false)}
                >   
                <Modal.Header closeButton>
                    <div style={{fontWeight:'bold',fontSize:'18px'}}>KYC Documents List</div>
                </Modal.Header>

                <Modal.Body>
                        <table 
                            border="1" 
                            style={{ 
                                width: '100%', 
                                borderCollapse: 'collapse', 
                                textAlign: 'center',
                                border: '2px solid black' 
                            }}
                        >
                            <thead>
                                <tr style={{...styleClass.pdfTable}}>
                                    <th style={{...styleClass.pdfTableHeader}}>S No.</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Doc Name</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Doc Description</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Upload Date</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Password</th>
                                    <th style={{...styleClass.pdfTableHeader}}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stateVals.kycArr && stateVals.kycArr.map((data, index) => (
                                    <tr style={{border:'1px solid black'}} key={index}>
                                        <td style={{...styleClass.pdfTableContent}}>{index+1}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{data.docName}</td>
                                        <td style={{ ...styleClass.pdfTableContent }}>{data.person !== "" ? `${mapArr[data.docDesc]} (Director ${data.person})` : mapArr[data.docDesc]}
                                        </td>
                                        <td style={{...styleClass.pdfTableContent}}>{formatDate(data.uploadDate)}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{data.pswrd}</td>
                                        <td style={{...styleClass.pdfTableContent}}> <i class="bi bi-box-arrow-up-right" onClick={() => stateVals.handleDownloadClick(data.docUrl)} style={{cursor:'pointer'}}></i> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
                </div>
            </div>
        </div>
    )
}

export default CompanyComponent