import React, { useEffect, useRef, useState} from 'react'
import axios from "axios";
import FormComponent from '../newHomeComponents/FormComponent'
import BenefitsComponent from '../newHomeComponents/BenefitsComponent'
import HeaderComponent from '../header/HeaderComponent'
import PartnerComponent from '../crm/PartnerComponent'
import KnowLoanComponent from '../knowloan/KnowloanComponent'
import TestimonialsComponent from '../testimonials/TestimonialsComponent'
import FindLoanComponent from '../findloan/FindloanComponent'

import FooterComponent from '../footer/FooterComponent'
import { getGlid } from '../../utils'

import { gatrack } from '../../utils';

function NewHomePage() {
  const targetDivRef = useRef(null);


  const scrollToTarget = () => {
    targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <HeaderComponent />
        <FormComponent ref={targetDivRef} />
        <BenefitsComponent onClick={scrollToTarget} />
        <KnowLoanComponent />
      <TestimonialsComponent/>
      <FindLoanComponent />
        <FooterComponent />
    </>
  )
}

export default NewHomePage