import Cookies from 'universal-cookie';
import axios from 'axios';
import { format } from 'date-fns';
const cookie = new Cookies();

export function getGlid(){
    const cookie = new Cookies();

    var glid = '';

    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => {
            sd = data.split("=")
            if (sd[0] == "glid") {
                glid = sd[1]
            }
        })
    }
    return glid;
}

export function getAK(){
    const cookie = new Cookies();

    var ak = '';

    let visitorData = cookie.get('im_iss');
   
    if (visitorData && typeof visitorData === 'string' && visitorData.startsWith('t=')) {
        const match = visitorData.match(/t=([^;]+)/);
       
        if (match) {
            ak = match[1]; 
        }
    }
    return ak;
}

export function clicktracking(vendor_name) {

    var glid = getGlid();
    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : 'NA',
        "action" : "Apply Now Click-SubPages",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }

}

export function gatrack(category, action, label, value = null) {
    
    var glid = getGlid();
    if (value) {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': value });
    }
    else {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': glid });
    }
}

export function glidtracker(lender_name, url_selector) {

    var glid = getGlid();

    if(lender_name == 'ICICI Business Loan')
    {
        var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";
    }
    else if(lender_name == 'Poonawalla Business Loan')
    {
        var url = "https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL &utm_term=business+loan&utm_content=bl&gclid=";
    }
    else if(lender_name == 'Tata Capital Business Loan'){
        if (url_selector == "Apply Now") {
            var url = "https://www.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART"
        }
        else {
            var url = "https://businessloan.tatacapital.com/online-tma/loans/tma/track-my-application#!"
        }
    }
    else if(lender_name == 'Indifi Business Loan'){
        var url = "https://www.indifi.com/associate/indiamart?utm_source=GLID&utm_campaign="
    }
    else if(lender_name == 'IIFL Business Loan'){
        var url = " https://iifl-loans.finbox.in/?partnerCode=II_YATOYJ&customerID="
    }

    

    if (glid) {
        if(lender_name == 'ICICI Business Loan')
        {
            url += ("&glid=" + glid);
        }
        else if(lender_name == 'Poonawalla Business Loan' || lender_name == 'Indifi Business Loan' || lender_name == 'IIFL Business Loan')
        {
            url += glid;
        }
        else if(lender_name == 'Tata Capital Business Loan')
        {
            url += ("&PartnerID=" + glid);
        }
        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}

export async function geturldata(vendor_name, url_selector) {
    var glid = getGlid();

    if(glid && glid != ''){
    var host = window.location.hostname;
    var gstData = await getGstData(glid);
    var encodedGstData = btoa(JSON.stringify(gstData));


    if (host == 'loans.indiamart.com') {
        var geturldataURL = "https://loans.indiamart.com/functions.php";
    }
    else {
        var geturldataURL = "https://dev-loans.indiamart.com/functions.php";
    }
    
    var geturldataPostData = {
        "functionName" : "geturldata",
        "glid": glid,
        "vendor_name": vendor_name,
        "url_selector": url_selector,
        "gstData": encodedGstData,
    };

    var formData = new FormData();
    for (var key in geturldataPostData) {
        formData.append(key, geturldataPostData[key]);
    }

        var response = await axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: geturldataURL,
            data: formData,
            timeout: 10000
        })

            var data_obj = response['data'];
            window.open(data_obj.red_url, '_blank');

    }
    else {
        if (vendor_name === "CreditEnable Unsecured") {
            window.open('https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?', '_blank');
        }
        else if (vendor_name === "CreditEnable Secured") {
            window.open('https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?', '_blank');
        }
        else if (vendor_name === "Pirimid") {
            window.open('https://indiamart.ignosis.ai/lsp/ui/api/indiamart/tenant/loan/init', '_blank');
        }
        else if (vendor_name === "CreditMantri") {
            window.open('https://www.creditmantri.com/Indiamart/?utm_content=alliance-lp&alliance_lender=Indiamart&utm_campaign=alliances&utm_source=Indiamart_CoBrandedPage&utm_term=alliance_indiamart', '_blank');
        }
        else if (vendor_name === "LoanTap") {
            window.open('https://loantap.in/journey/start/bl-im?', '_blank');
        }
        else if (vendor_name === "OneInfinity") {
            window.open('https://complementor.oneflo.in/complementor-widget/bil/CONN4128778344?', '_blank');
        }
        else if (vendor_name === "IndiaLends") {
            window.open('https://indiamart.indialends.com/?', '_blank');
        }
        else if (vendor_name === "Velocity") {
            window.open('https://indiamart.velocity.in/marketplace/indiamart?/', '_blank');
        }
        else if (vendor_name === "FinAgg") {
            window.open('https://portal.finagg.in/fame/indiamart/#/dealerassessment/login', '_blank');
        }

    }

}

export function getGlidEnd() {
    const glid = getGlid();
  
    var glidEnd = '';
    if (glid) {
      glidEnd = glid.charAt(glid.length - 1);
    }
  
    return glidEnd;
  }

  function storeGst(gstData, glid){

    const currentTime = new Date().getTime();
  
    const newData = {
      "gstdata":gstData,
      "timestamp": currentTime,
      "identifier":btoa(glid+'|'+'9846300')
    };
  
    sessionStorage.setItem('gstDetails', JSON.stringify(newData));
    //console.log("Data has been set or updated.");
  
  };
  
  
async function gstapi(glid = '') {

    var host = window.location.hostname;
    if (host == 'loans.indiamart.com') {
        var getgstDataURL = "https://loans.indiamart.com/api/v1/getGSTData";
    }
    else {
        var getgstDataURL = "https://dev-loans.indiamart.com/api/v1/getGSTData";
    }

    if(glid == ""){
        glid = getGlid();
    }

    var ak = getAK();

    //console.log("glid and ak in gstapi => ", glid, ak);

    if (ak != "" && ak != null && glid != "" && glid != null) {


        var getgstData = {
            "glid": glid,
            "src": "appNowClick",
            "ak": ak
        };

        var formData = new FormData();
        for (var key in getgstData) {
            formData.append(key, getgstData[key]);
        }

        try {
            let response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: getgstDataURL,
                data: JSON.stringify(getgstData),
                timeout: 10000
            });

            var data_obj = response['data'];
            //console.log("gst data_obj =>", data_obj);
            if (data_obj && data_obj != "" && data_obj != null) {
                storeGst(data_obj, glid);
            }
            return data_obj;
        }
        catch (error) {
            console.error("An error occurred in utils gstapi call ", error);
            return ""
        }
    } else{
        return ""
    }

} 
  
  export async function getGstData(glid) {
  
    const maxAge = 1 * 24 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const storedData = sessionStorage.getItem('gstDetails');
  
    if (storedData) {
    const { timestamp , identifier} = JSON.parse(storedData);
    const dec = atob(identifier);
    var storedId = dec.split("|")[0];
      if ((currentTime - timestamp < maxAge) && glid == storedId) {
        var data = JSON.parse(storedData);
        if(data && data.gstdata){
          return data.gstdata;
        } else{
            try {
                var gstOutput = await gstapi(glid);
                return gstOutput;
              } catch (error) {
                console.error('Error fetching GST data in getGstData utils:', error);
                return "";
              }
        }
      }
      else {
        try {
          var gstOutput = await gstapi(glid);
          //console.log("gst output =>", gstOutput);
          return gstOutput;
        } catch (error) {
          console.error('Error fetching GST data in getGstData utils:', error);
          return "";
        }
      }
    }
    else {
      try {
        var gstOutput = await gstapi(glid);
        //console.log("gst output =>", gstOutput);
        return gstOutput;
      } catch (error) {
        console.error('Error fetching GST data in getGstData utils:', error);
        return "";
      }
    }
  }

  export const lostReasonMap = {
    'aaa': 'Not minimum business vintage',
    'aab': 'Duplicate lead',
    'aac': 'Non-serviceable negative industry',
    'aad': 'Non-serviceable pincode tier 4 location',
    'aae': 'Same pan mobile disqualified or rejected for same lender in last 60 days',
    'aaf': 'Constitution is not proprietorship',
    'aag': 'GST disqualified',
    'aah': 'Business not registered',
    'aai': 'Low cibil score overdue in loan DPDS',
    'aaj': 'Delinquencies plus high leverage',
    'aak': 'Loan written off',
    'aal': 'High number of enquiries',
    'aam': 'Duplicate PAN',
    'aan': 'Low banking',
    'aao': 'Rejected by lender',
    'aap': 'Lack of financial discipline inconsistent revenue in recent past last 3 months turnover growth not sufficient',
    'aaq': 'Credit reject scorecard no hit banking not available',
    'aar': 'Low business turnover',
    'aas': 'Insufficient calculated income rule failed adjusted ABB should be >= 8000',
    'aat': 'Insufficient loan amount calculated ABB reason rule failed MCA >= 1L for Nexarc or MCA >= 10000',
    'aau': 'Insufficient transaction in account',
    'aav': 'Lack of financial discipline minimum average balance low negative',
    'aaw': 'Not eligible',
    'aax': 'Rejected by customer due not interested or requirement fulfilled',
    'aay': 'Journey expired',
    'aaz': 'Lead purged',
    'aba': 'Age is less'
  };

  export const loanTypeMap = {
    '0': 'Term loan',
    '1': 'Short term business loan',
    '2': 'Loan against property',
    '3': 'Overdraft',
    '4': 'Personal loan',
    '5': 'Credit card'
  };

    export const stageValMap = {
        1: "Application Created",
        2: "Documents Pending",
        3: "Logged In",
        4: "Credit Check",
        5: "Approved",
        6: "Disbursed"
    };

    export const stagePriorityMap = {
        "application created": "1",
        "documents pending": "2",
        "logged in": "3",
        "credit check": "4",
        "approved": "5",
        "disbursed": "6"
    };

    export function getIST(){
        const formattedDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss');
        return formattedDate;
      }

    export function getCurrentTime() {
        return new Date(); 
    }
    
     async function postGstData(glid="", status="", registration_date="", turnover="", time="", event="",gstin="",trade_name=""){
        try{
            //console.log(glid, status, registration_date, turnover, time, event)
            const host = window.location.hostname;
            const url = host == "loans.indiamart.com" ? "https://loans.indiamart.com/api/v1/readIntent" :"https://dev-loans.indiamart.com/api/v1/readIntent";

            var data = {glid:glid, status:status, regDate:registration_date, slab:turnover, time:time, event:event, encflag:'JB7h',gstin:gstin, trade_name: trade_name};
            var formData = new FormData();
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
            
            const response = await axios.post(url, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
               
              //console.log("Response from postGst API => ", response)
        
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error); 
        }
    }
    
 export  async function writeGst(glid, event){
        //console.log("In Store GST Function");
        try{
        
            var data_obj = await getGstData(glid);
            var status = '', registration_date='', turnover = '',gstin,trade_name='';
    
            if (data_obj['code'] == '200') {
    
                //console.log("Data Object => ", data_obj);
                if (data_obj['data']['values']['gstin_status']) {
                    status = data_obj['data']['values']['gstin_status'];
                }
                if (data_obj['data']['values']['registration_date']) {
                    registration_date = data_obj['data']['values']['registration_date'];
                }
                if(data_obj['data']['values']['annual_turnover_slab']){
                    turnover = data_obj['data']['values']['annual_turnover_slab'];
                }
                if(data_obj['gstnum']){
                    gstin = data_obj['gstnum']
                }
                if(data_obj['data']['values']['trade_name']){
                    trade_name = data_obj['data']['values']['trade_name']
                }
            }
            var time = getIST();
            postGstData(glid, status, registration_date, turnover, time, event,gstin, trade_name);
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error); 
        }
    }

    export function checkFinaggCriteria() {
        if (localStorage.getItem("gstDetails")) {
            var gstData = JSON.parse(localStorage.getItem("gstDetails"));
        } else {
            return false;
        }
    
        if (gstData && gstData['gstdata'] && gstData['gstdata']['data'] && gstData['gstdata']['data']['values']) {
            var gstValArr = gstData['gstdata']['data']['values'];
        } else {
            return false;
        }
    
        if (!gstValArr['gstin_number'] || !gstValArr['annual_turnover_slab'] || !gstValArr['registration_date'] || !gstValArr['gstin_status']) {
            return false;
        }
    
        var gstStatus = gstValArr['gstin_status'];
        if (gstStatus != 'Active') {
            return false;
        }
    
        var turnoverSlab = gstValArr['annual_turnover_slab'];
        if (turnoverSlab.includes('NA') || turnoverSlab.includes('No') || turnoverSlab == "" || turnoverSlab.includes('0 to 40 lakhs')) {
            return false;
        }
    
        var gstRegData = gstValArr['registration_date'];
    
        if (gstRegData) {
            const [day, month, year] = gstRegData.split('-');
    
            if (day && month && year) {
                const givenDate = new Date(year, month - 1, day);
                const currentDate = new Date();
                const yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
                var gstvintage = yearsDifference * 12 + (currentDate.getMonth() - givenDate.getMonth());
    
                if (gstvintage < 24) {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    
        return true;
    }

    export async function checkLoginStatus(){
        const cookies = new Cookies();
        
        const isImeshVisitorPresent = cookies.get('ImeshVisitor') !== undefined;
        const isImIssPresent = cookies.get('im_iss') !== undefined;
    
        if (isImeshVisitorPresent && isImIssPresent) {
            //console.log("Login check: Both 'ImeshVisitor' and 'im_iss' cookies are present.");
            let glid = getGlid();
            let ak = getAK();

            try{
                var host = window.location.hostname;
        
                if (host == 'loans.indiamart.com') {
                    var url = "https://loans.indiamart.com/api/v1/userLoginStatus";
                }
                else {
                    var url = "https://dev-loans.indiamart.com/api/v1/userLoginStatus";
                }

            var identifyData = {
                "glid":glid,
                "ak":ak
            };

            var formData = new FormData();
            for (var key in identifyData) {
                formData.append(key, identifyData[key]);
            }

            let response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: url,
                data: formData,
                timeout: 10000
            });

            if(response){
                
                
                if(response.data.CODE == "200" && response.data.FLAG == "1"){
                    //console.log("GLID and AK are vaid");
                    return "full";
                }

                //console.log("AK not matching with GLID");
            } 
            } catch(error){
                console.log("Error in AK validation API => ", error);
            }
        }
    
        if (isImeshVisitorPresent) {
            //console.log("Login check: 'ImeshVisitor' cookie is present.");
            return "partial"
        }
    
    
    
        //console.log("Login check: One or both cookies are not present.");
        return "none";
    
    }

export async function identifyGLID(mob) {
    var host = window.location.hostname;

    if (host == 'loans.indiamart.com') {
        var identifyurl = 'https://loans.indiamart.com/api/v1/getUser';
    }
    else {
        var identifyurl = 'https://dev-loans.indiamart.com/api/v1/getUser';
    }


    var identifyData = {
        "functionName": "identify",
        "mobile": mob
    };

    var formData = new FormData();
    for (var key in identifyData) {
        formData.append(key, identifyData[key]);
    }

    let response = await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: identifyurl,
        data: formData,
        timeout: 10000
    });

    if (response) {
        var data_obj = response['data'];

        if (data_obj.code == '200') {
            var glid = data_obj.DataCookie.glid;
            var cookiestring = getcookieready(data_obj.DataCookie);

            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30);

            const cookie = new Cookies();
            if (host == "loans.indiamart.com") {
                cookie.set('ImeshVisitor', cookiestring, { path: '/', domain: '.indiamart.com', expires: expirationDate });
            } else {
                cookie.set('ImeshVisitor', cookiestring, { path: '/', expires: expirationDate });
            }

            //console.log(glid)
            return glid
        }
    }

    return null;
}

    export function  getcookieready(dataarray) {
        const formattedString = Object.entries(dataarray)
            .map(([key, value]) => `${key}=${value}`)
            .join('|');
        
        //getCookieStr(formattedString);
        return formattedString;
    }

    export async function getMobAndGlid(){
        const cookie = new Cookies();
      
        var glid = '', mob='', sessionKey='';
        var resp = {"glid":glid, "mob":mob}
      
        let visitorData = cookie.get('ImeshVisitor');
        if (visitorData) {
            visitorData = visitorData.split("|");
      
            let sd = [];
            visitorData.map((data) => {
                sd = data.split("=")
                if (sd[0] == "glid") {
                    glid = sd[1]
                }
                if (sd[0] == "sessionKey") {
                    sessionKey = sd[1]
                }
                if(sd[0] == "mb1"){
                  mob = sd[1]
              }
            })
            if(glid && mob){
              resp = {"glid":glid, "mob":mob}
              //console.log("Response in getMobAndGlid =>", resp)
              return resp;
          }
      
        }
        
        if(glid && sessionKey){
            //console.log("Getting GLID and Session Key from cookie");
            const host = window.location.hostname;
        const url = host == "loans.indiamart.com" ? "https://loans.indiamart.com/api/v1/getSessionDetails" : "https://dev-loans.indiamart.com/api/v1/getSessionDetails";
      
        var data = { glid: glid, key:sessionKey};
        var formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
      
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
      
            if(response && response["data"] && response["data"]["code"] == 200){
                mob = response["data"]["DataCookie"]["mb1"];
            } else{
                console.log("Not getting 200 from Session Details API, response => ", response);
            }
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error);
        }
        }
      
        resp = {"glid":glid, "mob":mob}
        //console.log("Response in getMobAndGlid =>", resp)
        return resp;
      }
      
      export async function fullLogin(glid, mob, flag, key) {
        const host = window.location.hostname;
        const url = host == "loans.indiamart.com" ? "https://loans.indiamart.com/api/v1/fullLogin" : "https://dev-loans.indiamart.com/api/v1/fullLogin";
      
        var data = { glid: glid, mobile: mob, flag: flag, key: key };
        var formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
      
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
      
            return response;
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error);
        }
      }
      
      export async function setFullLoginCookie(imeshVal, issVal){
        const imeshString = getcookieready(imeshVal);
        const issString = `t=${issVal.t}`
        const host = window.location.hostname;
      
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
      
        //console.log("Imesh Val && iss val, expiration date => ", imeshString, issString, expirationDate);
      
        const cookie = new Cookies();

        if(host == 'loans.indiamart.com'){
            cookie.set('ImeshVisitor', imeshString, { path: '/',  domain: '.indiamart.com', expires: expirationDate });
            cookie.set('im_iss', issString, { path: '/',  domain: '.indiamart.com', expires: expirationDate })
        } else{
            cookie.set('ImeshVisitor', imeshString, { path: '/', expires: expirationDate });
            cookie.set('im_iss', issString, { path: '/', expires: expirationDate });
        }

        return
        
      }
      
      export function setLocalStorageWithExpiration(key, value, expirationInSeconds){
        const now = Date.now();
        const item = {
          value,
          expiration: now + expirationInSeconds * 1000,
        };
        localStorage.setItem(key, JSON.stringify(item));
      };
      
      export function getLocalStorageWithExpiration (key) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) return null;
      
        const item = JSON.parse(itemStr);
        if (Date.now() > item.expiration) {
          localStorage.removeItem(key);
          return null;
        }
        return item.value;
      };
      
      export function deleteFullLoginCookie(){
        const cookie = new Cookies();
        cookie.remove('im_iss');
      };


      export const eventBus = {
        events: {},
    
        subscribe(eventName, callback) {
            if (!this.events[eventName]) {
                this.events[eventName] = [];
            }
            this.events[eventName].push(callback);
        },
    
        unsubscribe(eventName, callback) {
            if (!this.events[eventName]) return;
            this.events[eventName] = this.events[eventName].filter((cb) => cb !== callback);
        },
    
        emit(eventName, data) {
            if (!this.events[eventName]) return;
            this.events[eventName].forEach((callback) => callback(data));
        },
    };

    export const fullLoginIds = new Set(["195143359", "131499053", "198123257", "236346447", "89548221", "149857522","132160368", "195943733", "135021980", "49320493"]);
     
    export function setLoginStatCookie(val) {
        let expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 10); 
    
        const cookie = new Cookies();
        cookie.set('loginStat', val, { path: '/', expires: expirationDate });
    }

    export function getCookieStr(formattedString) {
        const dataStr = formattedString.split('|').reduce((acc, pair) => {
            const [key, value] = pair.split('=');
            acc[key] = value;
            return acc;
        }, {});

        //console.log("dataStr => ", dataStr)
        return dataStr;
    }

    export async function checkImExpiry() {

        const glid = getGlid();
        const ak = getAK();

        if (glid === '' || ak === '') {
            return "absent";
          }
      
        const host = window.location.hostname;
        const url = host == "loans.indiamart.com"
          ? "https://loans.indiamart.com/api/v1/userLoginStatus"
          : "https://dev-loans.indiamart.com/api/v1/userLoginStatus";
      
        const formData = new FormData();
        formData.append("glid", glid);
        formData.append("ak", ak);
      
        try {
          const response = await axios.post(url, formData, {
            timeout: 3000,
          });
      
          if (response?.data?.CODE == "200" && response?.data?.FLAG == "1") {
            return "notExpired";
          }
      
        } catch (error) {
          console.error("Error in AK validation API =>", error);
        }
      
        return "expired"; 
      }
      
    export async function callReauth(){
        const host = window.location.hostname;
        const url = host == "loans.indiamart.com" ? "https://loans.indiamart.com/api/v1/reauth" : "https://dev-loans.indiamart.com/api/v1/reauth";

        let cookies = new Cookies();

        const imeshVal = cookies.get('ImeshVisitor') ;
        const ak = getAK();

        //console.log("cookie val imesh => ", imeshVal);

        const imesh =  JSON.stringify(getCookieStr(imeshVal)) ;



        //console.log("Imesh Val And ak val => ", imesh, ak);
        var data = { imesh: imesh, imiss:ak};
        var formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

      
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
      
            //console.log("Response from Reauth => ", response);
            if(response && response.data && response.status == "200"){
                //console.log("Reauth code success");
                if(response.data.DataCookie && response.data.im_iss){
                    setLoginStatCookie("true");
                    await setFullLoginCookie(response.data.DataCookie, response.data.im_iss);
                    decideRedirect();
                    //newAppendData();
                    //eventBus.emit("doneLogin", { message: "true" });
                }
            }
        } catch (error) {
            console.error("Error in call Reauth:", error);
        }
    }

    export function getEncGlidCookie() {
        var encVal = cookie.get('encGlid');
        
        return encVal || '';
      }
      
      export async function setEncrGLID(glid,ak) {
          var host = window.location.hostname;
          var url = host == "loans.indiamart.com" ? 
          "https://loans.indiamart.com/api/v1/getEncGlid" : 
          "https://dev-loans.indiamart.com/api/v1/getEncGlid";
      
          const formData = new FormData();
          formData.append('glid', glid);
          formData.append('AK', ak);
      
          try {
              const response = await axios.post(url, formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              });
              if(response.data.CODE == '200'){
                  cookie.set('encGlid',response.data.RESULT)
              }
          } catch (error) {
              console.error('Error:', error);
          }
      
      }
      
      export function isJourneyLive() {
        var glid = getGlid();
    
        const sampleJourneyGLID = [
            "195143359",
            "132160368",
            "59002668",
            "236346447",
            "13035342",
            "131002476"
        ];
    
        if (sampleJourneyGLID.includes(glid) || glid.slice(-1) == '1') {
            return true;
        }
    
        return false;
    }

export function isFinaggLive(glid) {
    if (!glid) {
        glid = getGlid();
    }

    const sampleJourneyGLID = [
        "132160368",
        "236346447",
        "13035342",
        "236169873"
    ];

    //const validEndings = ["12", "22", "32", "42", "52"];

    if (sampleJourneyGLID.includes(glid) || glid.endsWith('2')) {
        return true;
    }

    return false;
}

    export async function writeLogs (glid, input, output, status, serviceName, code, issue, reqTime, respTime, totalTime) {
        // const payload = { glid, input, output, status, serviceName, code, issue, reqTime, respTime, totalTime };
        let host = window.location.hostname;
        const url = host === "loans.indiamart.com" ? 
        "https://loans.indiamart.com/api/v1/setLoansLogs" : 
        "https://dev-loans.indiamart.com/api/v1/setLoansLogs";
      
        const formData = new URLSearchParams();
    
      formData.append("glid", glid);
      formData.append("input", input);
      formData.append("output", output);
      formData.append("status", status);
      formData.append("serviceName", serviceName);
      formData.append("code", code);
      formData.append("issue", issue);
      formData.append("reqTime", reqTime);
      formData.append("respTime", respTime);
      formData.append("totalTime", totalTime);
    
      try {
        const response = await axios.post(url, formData, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
    
        console.log("Response from SetLoansLogs API =>", response.data);
        return response.data;
      } catch (error) {
        console.error("Error from SetLoansLogs API =>", error.response?.data || error.message);
        return { error: error.response?.data || error.message };
      }
      };

      export async function newAppendData(glid='', startTime=''){
        if(glid == ''){
            glid = getGlid();
        }
        if(startTime == ''){
            startTime = getCurrentTime();
        }
        var host = window.location.hostname;
        const queryParamString = window.location.search.substring(1);
        //console.log("Query param string => ", queryParamString);
        let ak = getAK();
        var url = host == "loans.indiamart.com"? "https://loans.indiamart.com/api/v1/getEncValue":"https://dev-loans.indiamart.com/api/v1/getEncValue";
    
        const formData = new FormData();
        formData.append('glid', glid);
        formData.append('ak', ak);
      
        try {
          const response = await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if(response.data.CODE == '200'){
            //console.log("Output from encVal => ", response.data.OUTPUT)
            var finalUrl = host=="loans.indiamart.com"?"https://www.hellotrade.com"+"?encVal="+response.data.OUTPUT+"&userSourceData=loans,"+queryParamString :"http://localhost:3000/"+"?encVal="+response.data.OUTPUT+"&userSourceData=loans,"+queryParamString;
    
            const endTime = getCurrentTime();
            const timeDifference = endTime.getTime() - startTime.getTime();
    
            writeLogs(glid, "NA", response.data.OUTPUT, "SUCCESS", "APPEND_DATA", 200, "NA", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
    
            window.location.replace(finalUrl);
          }else{
            const endTime = getCurrentTime();
            const timeDifference = endTime.getTime() - startTime.getTime();
            writeLogs(glid, ak, JSON.stringify(response), "FAILURE", "APPEND_DATA", 400, "CODE is not 200", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
          }
        } catch (error) {
            const endTime = getCurrentTime();
            const timeDifference = endTime.getTime() - startTime.getTime();
            writeLogs(glid, ak, "NA", "FAILURE", "APPEND_DATA", 500, error, format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
          console.error('Error:', error);
        }
    }

    export async function getHtUrl(glid) {
        const ak = getAK();
        if (!glid || !ak) return "";
      
        const host = window.location.hostname;
        const queryParamString = window.location.search.substring(1);
      
        const apiUrl = host == "loans.indiamart.com"
          ? "https://loans.indiamart.com/api/v1/getEncValue"
          : "https://dev-loans.indiamart.com/api/v1/getEncValue";
      
        const redirectUrlBase = host == "loans.indiamart.com"
          ? "https://www.hellotrade.com"
          : "http://localhost:3000";
      
        const formData = new FormData(); 
        formData.append("glid", glid);
        formData.append("ak", ak);
      
        try {
          const response = await axios.post(apiUrl, formData, {
            timeout: 3000,
          });
      
          const result = response.data; 
      
          if (result?.CODE == "200" && result?.OUTPUT) { 
            return `${redirectUrlBase}?encVal=${result.OUTPUT}&userSourceData=loans,${queryParamString}`;
          } else {
            urlLogFailure(glid, JSON.stringify(result), "Invalid response structure or CODE not 200");
          }
        } catch (error) {
          urlLogFailure(glid, error, error.message);
          console.error("API Error:", error);
        }
      
        return "";
      }
      
      function urlLogFailure(glid, response, reason) {
        const currTime = getCurrentTime();
        writeLogs(glid, "Desktop", response, "FAILURE", "APPEND_HT_DATA", 400, reason,
          format(currTime, "dd-MM-yyyy HH:mm:ss"),
          format(currTime, "dd-MM-yyyy HH:mm:ss"),
          0
        );
      }
      
      export function redirectToHt(glid, redUrl, startTime){
        const endTime = getCurrentTime();
        const timeDifference = endTime.getTime() - startTime.getTime();

        if (!redUrl || redUrl.trim() === ""){
            writeLogs(glid, "Desktop",  redUrl || "N/A", "FAILURE", "APPEND_HT_DATA", 400, "Empty Redirection URL", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
            return;
        }

        writeLogs(glid, "Desktop", redUrl, "SUCCESS", "APPEND_HT_DATA", 200, "NA", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
      
        window.location.replace(redUrl);
      }

      export async function getFinaggUrl(glid) {
        const ak = getAK();
        if (!glid || !ak) return "";
      
        const host = window.location.hostname;
      
        const apiUrl = host == "loans.indiamart.com"
          ? "https://loans.indiamart.com/api/v1/getEncFinagg"
          : "https://dev-loans.indiamart.com/api/v1/getEncFinagg";
      
        const redirectUrlBase = host == "loans.indiamart.com"
          ? "https://www.hellotrade.com/business-loan/#/login"
          : " https://dev.hellotrade.com/business-loan/#/dealerassessment/login";
      
        const formData = new FormData(); 
        formData.append("glid", glid);
        formData.append("ak", ak);
      
        try {
          const response = await axios.post(apiUrl, formData, {
            timeout: 3000,
          });
      
          const result = response.data; 
      
          if (result?.CODE == "200" && result?.OUTPUT) { 
            return `${redirectUrlBase}?Idata=${encodeURIComponent(result.OUTPUT)}`;
          } else if(result?.CODE == "201") {
            if (["132160368", "236346447", "13035342","236169873"].includes(glid)){
                return `${redirectUrlBase}?Idata=${encodeURIComponent(result.OUTPUT)}`;
            } else{
                newAppendData(glid);
                return "non-prop";
            }
          }else {
            finaggUrlLogFailure(glid, JSON.stringify(result), "Invalid response structure or CODE not 200 or 201");
          }
        } catch (error) {
            finaggUrlLogFailure(glid, error, error.message);
          console.error("API Error:", error);
        }
      
        return "";
      }
      
      function finaggUrlLogFailure(glid, response, reason) {
        const currTime = getCurrentTime();
        writeLogs(glid, "Desktop", response, "FAILURE", "APPEND_FINAGG_DATA", 400, reason,
          format(currTime, "dd-MM-yyyy HH:mm:ss"),
          format(currTime, "dd-MM-yyyy HH:mm:ss"),
          0
        );
      }

      export function redirectToFinagg(glid, redUrl, startTime=''){
        const endTime = getCurrentTime();
        if(startTime==''){
            startTime = endTime;
        }
        console.log("starttime = > ", startTime);
        const timeDifference = endTime.getTime() - startTime.getTime();

        if (!redUrl || redUrl.trim() === "" || redUrl == "non-prop"){
            writeLogs(glid, "Desktop",  redUrl || "N/A", "FAILURE", "APPEND_FINAGG_DATA", 400, "Empty Redirection URL", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
            return;
        }

        writeLogs(glid, "Desktop", redUrl, "SUCCESS", "APPEND_FINAGG_DATA", 200, "NA", format(startTime, 'dd-MM-yyyy HH:mm:ss'), format(endTime, 'dd-MM-yyyy HH:mm:ss'), timeDifference)
      
        window.location.replace(redUrl);
      }
    
      export async function decideRedirect(){
        let glid = getGlid();

        if(isFinaggLive(glid)){
            let finUrl = await getFinaggUrl(glid);
            redirectToFinagg(glid, finUrl);
        } else{
            newAppendData(glid);
        }
      }