import React, { useEffect, useState } from 'react'
import { getIndBureau, handleDownloadClick } from '../../crmUtils';
import { Modal, Button } from "react-bootstrap";
import { useBureauContext } from './AuthComponent';



const styles = {
    container: {
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '270px',
        position: 'absolute',
        top: '40%',
        left: '40%',
        backgroundColor: '#f5f5f5',
        fontFamily: 'Manrope'
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    label: {
        paddingRight: '10px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    radioGroup: {
        marginBottom: '20px',
    },
    radioLabel: {
        display: 'block',
        marginBottom: '10px',
    },
    radio: {
        marginRight: '8px',
    },
    authContainer: {
        textAlign: 'center',
    },
    uiContainer: {
        textAlign: 'left',
    },
    midSection: {
        backgroundColor: '#A2CFFF',
        // height:'200px',
        borderRadius: '8px',
        margin: '20px',
        padding: '20px',
        fontSize: '14px'
    },
    allSec: {
        width: '25%',
        backgroundColor: '#A2CFFF',
        borderRadius: '8px',
        margin: '20px',
        padding: '20px',
        fontSize: '14px'
    },
    bankingCTA: {
        marginBottom: '10px',
        padding: '4px 8px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007BFF', // Button color
        color: '#FFF', // Text color
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        // margin:'5px'
    },
    // fileInput: {
    //     opacity: 0, 
    //     position: 'absolute', 
    //     zIndex: 1, 
    //     width: '100%', 
    //     height: '100%',
    //     cursor: 'pointer'
    // },
    customButton: {
        display: 'inline-block',
        padding: '4px 8px',
        borderRadius: '5px',
        backgroundColor: '#007BFF',
        color: '#FFF',
        fontSize: '16px',
        cursor: 'pointer',
        margin: '5px'
    },
    datePicker: {
        padding: '4px',
        borderRadius: '5px',
        border: '1px solid #007BFF',
        backgroundColor: '#FFF',
        color: '#333',
        fontSize: '14px',
        marginRight: '10px'
    },
    pdfTableContent: {
        border: '1px solid black'
    },
    pdfTableHeader: {
        border: '2px solid black'
    },
    loanDet: {
        // width: '100%',
        borderCollapse: 'collapse',
        margin: '10px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        borderRadius: '8px',
    },
    labelCell: {
        padding: '4px 6px',
        backgroundColor: '#f7f9fc', // light grey for labels
        color: '#333',
        fontWeight: '600',
        borderBottom: '2px solid #e0e0e0',
        textAlign: 'left',
        borderRadius: '8px 0 0 8px', // rounded left corners
    },
    valueCell: {
        padding: '6px 20px',
        backgroundColor: '#fff',
        color: '#333',
        textAlign: 'center',
        fontWeight: '500',
        borderBottom: '2px solid #e0e0e0',
        borderRadius: '0 8px 8px 0', // rounded right corners
    },
    tatContainer: {
        margin: '10px 0',
        position: 'absolute',
        right: '120px',
        width: '20%',
        padding: '4px 6px',
        backgroundColor: '#f7f9fc',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: '#333',
        fontWeight: '600',
        fontSize: '16px',
        textAlign: 'center',
        border: '1px solid #e0e0e0',
        transition: 'all 0.3s ease',
    },
    countingText: {
        marginBottom: '0px',
        color: '#ff5733',
        fontWeight: '500',
        fontSize: '14px',
    },
    googleButton: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: '#4285F4',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s',
        margin: '0 auto'
    },
};

function IndBureauComponent() {
    const [bureauArray, setBureauArray] = useState([]);
    const {glid, showIndBureau, setShowIndBureau} = useBureauContext();

    const formatDate = (uploadDate) => {
        if (uploadDate) {
            const date = new Date(uploadDate); 
            const day = String(date.getUTCDate()).padStart(2, '0'); 
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
            const year = date.getUTCFullYear(); 
    
            const formattedDate = `${day}-${month}-${year}`;

            return formattedDate;
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            const data = await getIndBureau(glid);
            if (isMounted) {
                setBureauArray(data || []);
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Cleanup
        };
    }, [glid]);
  return (
    <>
        <Modal
                    show={showIndBureau}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShowIndBureau(false)}
                >
                    <Modal.Header closeButton>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>List of PDF</div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: '200px' }}>Total Count: {bureauArray.length}</div>
                    </Modal.Header>

                    <Modal.Body>
                        <table
                            border="1"
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                                border: '2px solid black' // Add a thicker border around the table
                            }}
                        >
                            <thead>
                                <tr style={{ ...styles.pdfTable }}>
                                    <th style={{ ...styles.pdfTableHeader }}>S No.</th>
                                    <th style={{ ...styles.pdfTableHeader }}>GLID</th>
                                    {/* <th style={{...styles.pdfTableHeader}}>Doc Stage</th>
                                   <th style={{...styles.pdfTableHeader}}>Doc Name</th>
                                   <th style={{...styles.pdfTableHeader}}>Duration</th> */}
                                    <th style={{ ...styles.pdfTableHeader }}>Upload Date</th>
                                    <th style={{ ...styles.pdfTableHeader }}>Features</th>
                                    <th style={{ ...styles.pdfTableHeader }}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bureauArray.length > 0 ? (
                                    bureauArray.map((bureauData, index) => (
                                        <tr style={{ border: '1px solid black' }} key={index}>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{index + 1}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{bureauData.glid}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{formatDate(bureauData.uploadDate)}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent , textAlign:"left"}}>
                                                {bureauData.features.split(",").map((item, index) => (
                                                    <div key={index}>{item.trim()}</div>
                                                ))}
                                            </td>

                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>
                                                <i className="bi bi-box-arrow-up-right"
                                                    onClick={() => handleDownloadClick(bureauData.docUrl)}
                                                    style={{ cursor: 'pointer' }}>
                                                </i>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: "center", padding: "10px", fontWeight: "bold" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </Modal.Body>
                </Modal>
    </>
  )
}

export default IndBureauComponent