import React, { useState, useEffect } from "react";
import "../../CssFiles/crmCss/consolidatedComp.css"; // Import CSS file
import { Modal, Button } from "react-bootstrap";
import { getConsolidatedData, handleDownloadClick } from "../../crmUtils";

const styles = {
    container: {
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '270px',
        position: 'absolute',
        top: '40%',
        left: '40%',
        backgroundColor: '#f5f5f5',
        fontFamily: 'Manrope'
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    label: {
        paddingRight: '10px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    radioGroup: {
        marginBottom: '20px',
    },
    radioLabel: {
        display: 'block',
        marginBottom: '10px',
    },
    radio: {
        marginRight: '8px',
    },
    authContainer: {
        textAlign: 'center',
    },
    uiContainer: {
        textAlign: 'left',
    },
    midSection: {
        backgroundColor: '#A2CFFF',
        // height:'200px',
        borderRadius: '8px',
        margin: '20px',
        padding: '20px',
        fontSize: '14px'
    },
    allSec: {
        width: '25%',
        backgroundColor: '#A2CFFF',
        borderRadius: '8px',
        margin: '20px',
        padding: '20px',
        fontSize: '14px'
    },
    bankingCTA: {
        marginBottom: '10px',
        padding: '4px 8px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007BFF', // Button color
        color: '#FFF', // Text color
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        // margin:'5px'
    },
    // fileInput: {
    //     opacity: 0, 
    //     position: 'absolute', 
    //     zIndex: 1, 
    //     width: '100%', 
    //     height: '100%',
    //     cursor: 'pointer'
    // },
    customButton: {
        display: 'inline-block',
        padding: '4px 8px',
        borderRadius: '5px',
        backgroundColor: '#007BFF',
        color: '#FFF',
        fontSize: '16px',
        cursor: 'pointer',
        margin: '5px'
    },
    datePicker: {
        padding: '4px',
        borderRadius: '5px',
        border: '1px solid #007BFF',
        backgroundColor: '#FFF',
        color: '#333',
        fontSize: '14px',
        marginRight: '10px'
    },
    pdfTableContent: {
        border: '1px solid black'
    },
    pdfTableHeader: {
        border: '2px solid black'
    },
    loanDet: {
        // width: '100%',
        borderCollapse: 'collapse',
        margin: '10px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        borderRadius: '8px',
    },
    labelCell: {
        padding: '4px 6px',
        backgroundColor: '#f7f9fc', // light grey for labels
        color: '#333',
        fontWeight: '600',
        borderBottom: '2px solid #e0e0e0',
        textAlign: 'left',
        borderRadius: '8px 0 0 8px', // rounded left corners
    },
    valueCell: {
        padding: '6px 20px',
        backgroundColor: '#fff',
        color: '#333',
        textAlign: 'center',
        fontWeight: '500',
        borderBottom: '2px solid #e0e0e0',
        borderRadius: '0 8px 8px 0', // rounded right corners
    },
    tatContainer: {
        margin: '10px 0',
        position: 'absolute',
        right: '120px',
        width: '20%',
        padding: '4px 6px',
        backgroundColor: '#f7f9fc',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        color: '#333',
        fontWeight: '600',
        fontSize: '16px',
        textAlign: 'center',
        border: '1px solid #e0e0e0',
        transition: 'all 0.3s ease',
    },
    countingText: {
        marginBottom: '0px',
        color: '#ff5733',
        fontWeight: '500',
        fontSize: '14px',
    },
    googleButton: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: '#4285F4',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s',
        margin: '0 auto'
    },
};

function ConsolidatedDataComponent() {
    const [selectedRange, setSelectedRange] = useState("last_1_day");
    const [selectedRange2, setSelectedRange2] = useState("last_1_day");
    const [totalCount, setTotalCount] = useState(0);
    const [bankingCount, setBankingCount] = useState(0);
    const [showBureau, setShowBureau] = useState(false);
    const [showBanking, setShowBanking] = useState(false);
    const [bureauArray, setBureauArray] = useState([]);
    const [bankingArray, setBankingArray] = useState([]);
    // const bureauArray = [
    //     {
    //         glid: "195143359",
    //         uploadDate: "2024-02-10T14:30:00Z",
    //         features: "Credit Score: 750, Loan History: 5 years, Credit Score: 750, Loan History: 5 years",
    //         docUrl: "https://example.com/bureau_report_1.pdf"
    //     },
    //     {
    //         glid: "195143359",
    //         uploadDate: "2024-02-09T10:15:00Z",
    //         features: "Credit Score: 680, Loan History: 3 years",
    //         docUrl: "https://example.com/bureau_report_2.pdf"
    //     },
    //     {
    //         glid: "195143359",
    //         uploadDate: "2024-02-08T18:45:00Z",
    //         features: "Credit Score: 800, Loan History: 8 years",
    //         docUrl: "https://example.com/bureau_report_3.pdf"
    //     },
    // ];

    const handleGetBureau = async () => {
        setShowBureau(true);
        const mockData = {
            last_1_day: 1,
            last_2_days: 2,
            last_7_days: 7,
            last_15_days: 15,
            last_30_days: 30,
        };
        const data = await getConsolidatedData(mockData[selectedRange], "bureau");
        console.log("data in my func => ", data);
        if (data != null) {
            setBureauArray(data);
        } else {
            setBureauArray([]);
        }

    };

    const handleGetBanking = async () => {
        setShowBanking(true);
        const mockData = {
            last_1_day: 1,
            last_2_days: 2,
            last_7_days: 7,
            last_15_days: 15,
            last_30_days: 30,
            last_45_days: 45,
            last_60_days: 60,
            last_90_days: 90,
        };
        const data = await getConsolidatedData(mockData[selectedRange2], "banking");
        console.log("data in my func => ", data);
        if (data != null) {
            console.log("setting banking array");
            setBankingArray(data);
        } else {
            setBankingArray([]);
        }

    };

    const formatDate = (uploadDate) => {
        if (uploadDate) {
            const date = new Date(uploadDate); 
            const day = String(date.getUTCDate()).padStart(2, '0'); 
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
            const year = date.getUTCFullYear(); 
    
            const formattedDate = `${day}-${month}-${year}`;

            return formattedDate;
        }
    }
    


    return (
        <>
            <div className="row" style={{maxWidth:'100%'}}>
                <div className="col-6">
                    <div className="consolidated-data">
                        <h2>Bureau Reports</h2>

                        <div className="controls">
                            <label htmlFor="dateRange">Select Range:</label>
                            <select
                                id="dateRange"
                                value={selectedRange}
                                onChange={(e) => setSelectedRange(e.target.value)}
                            >
                                <option value="last_1_day">Last 1 Day</option>
                                <option value="last_2_days">Last 2 Days</option>
                                <option value="last_7_days">Last 7 Days</option>
                                <option value="last_15_days">Last 15 Days</option>
                                <option value="last_30_days">Last 30 Days</option>
                            </select>

                            <button onClick={handleGetBureau}>Get All Bureau</button>
                        </div>

                        {/* <div className="count-box">
                <h3>Total Count</h3>
                <p>{totalCount}</p>
            </div> */}
                    </div>
                </div>


                <div className="col-6">
                    <div className="consolidated-data">
                        <h2>Banking Reports</h2>

                        <div className="controls">
                            <label htmlFor="dateRange">Select Range:</label>
                            <select
                                id="dateRange"
                                value={selectedRange2}
                                onChange={(e) => setSelectedRange2(e.target.value)}
                            >
                                <option value="last_1_day">Last 1 Day</option>
                                <option value="last_2_days">Last 2 Days</option>
                                <option value="last_7_days">Last 7 Days</option>
                                <option value="last_15_days">Last 15 Days</option>
                                <option value="last_30_days">Last 30 Days</option>
                                <option value="last_45_days">Last 45 Days</option>
                                <option value="last_60_days">Last 60 Days</option>
                                <option value="last_90_days">Last 90 Days</option>
                            </select>

                            <button onClick={handleGetBanking}>Get All Banking</button>
                        </div>

                        {/* <div className="count-box">
                <h3>Total Count</h3>
                <p>{totalCount}</p>
            </div> */}
                    </div>
                </div>
            </div>


            {

                <Modal
                    show={showBureau}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShowBureau(false)}
                >
                    <Modal.Header closeButton>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>List of PDF</div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: '200px' }}>Total Count: {bureauArray.length}</div>
                    </Modal.Header>

                    <Modal.Body>
                        <table
                            border="1"
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                                border: '2px solid black' // Add a thicker border around the table
                            }}
                        >
                            <thead>
                                <tr style={{ ...styles.pdfTable }}>
                                    <th style={{ ...styles.pdfTableHeader }}>S No.</th>
                                    <th style={{ ...styles.pdfTableHeader }}>GLID</th>
                                    {/* <th style={{...styles.pdfTableHeader}}>Doc Stage</th>
                                   <th style={{...styles.pdfTableHeader}}>Doc Name</th>
                                   <th style={{...styles.pdfTableHeader}}>Duration</th> */}
                                    <th style={{ ...styles.pdfTableHeader }}>Upload Date</th>
                                    <th style={{ ...styles.pdfTableHeader }}>Features</th>
                                    <th style={{ ...styles.pdfTableHeader }}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bureauArray.length > 0 ? (
                                    bureauArray.map((bureauData, index) => (
                                        <tr style={{ border: '1px solid black' }} key={index}>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{index + 1}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{bureauData.glid}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{formatDate(bureauData.uploadDate)}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent, textAlign:"left" }}>
                                                {bureauData.features.split(",").map((item, index) => (
                                                    <div key={index}>{item.trim()}</div>
                                                ))}
                                            </td>

                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>
                                                <i className="bi bi-box-arrow-up-right"
                                                    onClick={() => handleDownloadClick(bureauData.docUrl)}
                                                    style={{ cursor: 'pointer' }}>
                                                </i>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: "center", padding: "10px", fontWeight: "bold" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </Modal.Body>
                </Modal>

            }

            {

                <Modal
                    show={showBanking}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShowBanking(false)}
                >
                    <Modal.Header closeButton>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>List of PDF</div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: '200px' }}>Total Count: {bankingArray.length}</div>
                    </Modal.Header>

                    <Modal.Body>
                        <table
                            border="1"
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                                border: '2px solid black' // Add a thicker border around the table
                            }}
                        >
                            <thead>
                                <tr style={{ ...styles.pdfTable }}>
                                    <th style={{ ...styles.pdfTableHeader }}>S No.</th>
                                    <th style={{ ...styles.pdfTableHeader }}>GLID</th>
                                    {/* <th style={{...styles.pdfTableHeader}}>Doc Stage</th>
                                   <th style={{...styles.pdfTableHeader}}>Doc Name</th>*/}
                                    {/* <th style={{...styles.pdfTableHeader}}>Duration</th>  */}
                                    <th style={{ ...styles.pdfTableHeader }}>Upload Date</th>
                                    <th style={{ ...styles.pdfTableHeader }}>Bank Name</th>
                                    <th style={{ ...styles.pdfTableHeader }}>Password</th>
                                    <th style={{ ...styles.pdfTableHeader }}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankingArray.length > 0 ? (
                                    bankingArray.map((bankingData, index) => (
                                        <tr style={{ border: '1px solid black' }} key={index}>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{index + 1}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{bankingData.glid}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{formatDate(bankingData.uploadDate)}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>
                                                {(() => {
                                                    try {
                                                        // Attempt to parse the `duration` string as JSON
                                                        const parsedData = JSON.parse(bankingData.duration);

                                                        // Check if "Bank Name" exists in the parsed object
                                                        if (parsedData["Bank Name"]) {
                                                            return parsedData["Bank Name"];
                                                        }
                                                    } catch (e) {
                                                        // If parsing fails, simply return the original value
                                                    }

                                                    // Default: Display the original `duration` value
                                                    return bankingData.duration;
                                                })()}
                                            </td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>{bankingData.password}</td>
                                            <td className="scrollable-td" style={{ ...styles.pdfTableContent }}>
                                                <i className="bi bi-box-arrow-up-right" onClick={() => handleDownloadClick(bankingData.docUrl)} style={{ cursor: 'pointer' }}></i>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: "center", padding: "10px", fontWeight: "bold" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </Modal.Body>
                </Modal>

            }
        </>
    );
}

export default ConsolidatedDataComponent;
